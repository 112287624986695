/* eslint-disable camelcase */

((window, document) => {
  let data = null;
  const overallContainer = document.querySelector('.js-make-model-search');
  const makeSelect = document.getElementById('make');
  const modelSelect = document.getElementById('model');
  const typeSelect = document.getElementById('type');
  const minSelect = document.getElementById('minPrice');
  const maxSelect = document.getElementById('maxPrice');
  const budgetHolder = document.querySelector('.js-budget');
  const budgetSelect = document.querySelector('.js-budget-display');
  const budgetDropDown = document.querySelector('.js-budgetSelector');
  const resetBudget = document.querySelector('.js-resetBudget');
  const budgetDone = document.querySelector('.js-done');
  const submitButton = document.getElementById('submitButton');
  const resetFiltersButton = document.getElementById('resetFilters');

  /**
   * Populates the 'Search' button
   * @method populateCount
   * @param {number} count
   * @param {string} make
   */
  function populateCount(count, make = null) {
    if (!make) {
      submitButton.innerHTML =
        count === 0 ? 'No vehicles found' : `Search ${count} van${count === 1 ? '' : 's'}`;
      submitButton.disabled = count === 0;
    } else {
      if (make === 'Mercedes-Benz') {
        make = 'Mercedes';
      }
      submitButton.innerHTML = `View ${make} vans`;
    }
  }

  /**
   * Return a sub-set of data based on 'fieldKey' to build a select
   * @method getFacetData
   * @param {object} data
   * @param {string} fieldKey
   * @returns An array of data to populate the relevant select
   */
  function getFacetData(data, fieldKey) {
    return Object.values(data.facet_counts.vcj_facet_fields[fieldKey]).map(
      ({ [`${fieldKey}_label`]: label, [`${fieldKey}_code`]: code }) => [label, code],
    );
  }

  /**
   * Returns a range of values in an array
   * @method createRange
   * @param {object} data
   * @returns an array of values used to create a budget range select
   */
  function createRange(data) {
    let minPrice = data.stats.stats_fields.monprice.min;
    let maxPrice = data.stats.stats_fields.monprice.max;
    // -- Round up/down @ 10 --------
    minPrice -= minPrice % 10;
    maxPrice += 10 - (maxPrice % 10);
    // ------------------------------
    const range = [minPrice];
    for (let i = minPrice + 10; i <= maxPrice; i += 10) {
      if (i <= 200 || (i <= 500 && i % 50 === 0) || (i > 500 && i % 100 === 0)) {
        range.push(i);
      }
    }
    if (range.at(-1) < maxPrice) {
      range.push(maxPrice);
    }
    return range;
  }

  /**
   * Gets the number of vehicles based on the filtered data
   * @method getCount
   * @param {object} data
   * @returns A number corresponding to the number of filtered vehicles
   */
  function getCount(data) {
    const makeCounts = data.facet_counts.facet_fields.make.filter((a, i) => i % 2 === 1);
    return makeCounts.reduce((partialSum, a) => partialSum + a, 0);
  }

  /**
   * Resets the budget display to it's initial state
   * @method resetBudgetDisplay
   */
  function resetBudgetDisplay() {
    budgetSelect.innerHTML = 'Budget: Any min → Any max';
  }

  /**
   * Populates the min/max budget selectors with the supplied range values
   * @method buildBudgetSelects
   * @param {array} data
   */
  function buildBudgetSelects(data) {
    const selects = [minSelect, maxSelect];
    selects.forEach((selectElement) => {
      selectElement.options.length = 1;
      selectElement.disabled = false;
      data.forEach((value) => {
        const optionElement = new Option(`£${value}`, value);
        selectElement.appendChild(optionElement);
      });
    });
  }

  /**
   * Populates a specific select with the supplied data
   * @method buildSelect
   * @param {array} data
   * @param {object} element
   */
  function buildSelect(data, element) {
    element.options.length = 1;
    element.disabled = !data.length;
    data.forEach(([text, value]) => {
      const optionElement = new Option(text, value);
      element.appendChild(optionElement);
    });
  }

  /**
   * Gets data from the server based on the selected options
   * @method getData
   * @returns {object}
   */
  async function getData() {
    const fetchURL = '/content/api/json_veh_srch.p?';
    let URLParams = {
      qsMode: 'FORMQ',
      qsPriceType: 'MONTHLY',
      qsQueryId: 'Stock',
      start: 0,
      rows: 0,
      qsSearchTyp: 'NL',
      qsMinMonthly: minSelect.value,
      qsMaxMonthly: maxSelect.value,
      qsMake: makeSelect.value,
      qsModel: modelSelect.value,
      qsVanType: typeSelect.value,
      qsResultsContainer: 'MAKE_MODEL_SEARCH_JSON',
    };
    URLParams = new URLSearchParams(URLParams).toString();
    const response = await fetch(fetchURL + URLParams);
    const data = await response.json();
    return data;
  }

  /**
   * Populates the search button with 'Loading' for 750ms
   * @method loadingDisplay
   */
  async function loadingDisplay() {
    submitButton.disabled = true;
    submitButton.innerHTML = '<span class="make-model-search--loading">Loading...</span>';
    await new Promise((r) => {
      setTimeout(r, 750);
    });
    submitButton.disabled = false;
  }

  /**
   * Enables or disables the main reset button based on selections
   * @method enableMainReset
   */
  function enableMainReset() {
    resetFiltersButton.disabled = ![makeSelect, typeSelect, minSelect, maxSelect].some(
      (el) => el.value !== '',
    );
  }

  /**
   * Resets all selects to their initial state
   * @method resetFilters
   */
  async function resetFilters() {
    await loadingDisplay();
    const selects = [makeSelect, modelSelect, typeSelect, minSelect, maxSelect];
    selects.forEach((selectElement) => {
      selectElement.options.length = 1;
      selectElement.disabled = true;
    });
    data = await getData();
    buildSelect(getFacetData(data, 'make'), makeSelect);
    buildSelect(getFacetData(data, 'vanType'), typeSelect);
    buildBudgetSelects(createRange(data));
    populateCount(getCount(data));
    resetBudgetDisplay();
    enableMainReset();
  }

  /**
   * Disables or enables options in the budget selects based on what has been selected
   * @method processBudget
   * @param {object} select
   */
  function processBudget(select) {
    const selectedMin = minSelect.value;
    const selectedMax = maxSelect.value;
    const minValues = [...minSelect.options].map((o) => o);
    const maxValues = [...maxSelect.options].map((o) => o);
    if (select === 'min') {
      maxValues.forEach((option) => {
        if (option.value < selectedMin && option.index !== 0 && selectedMin !== '') {
          option.disabled = true;
        } else {
          option.disabled = false;
        }
      });
    } else {
      minValues.forEach((option) => {
        if (option.value > selectedMax && selectedMax !== '') {
          option.disabled = true;
        } else {
          option.disabled = false;
        }
      });
    }
  }

  /**
   * Sets the session storage based on the selections made
   * @method setSession
   * @param {string} sessionBase
   * @param {string} sessionExtra
   */
  function setSession(sessionBase, sessionExtra = '') {
    const selectedMake = makeSelect.value;
    const selectedModel = modelSelect.value;
    const selectedType = typeSelect.value;
    const selectedMin = minSelect.value === '' ? '' : parseInt(minSelect.value, 10);
    const selectedMax = maxSelect.value === '' ? '' : parseInt(maxSelect.value, 10);
    const sessionKey = `${sessionBase}/NL${sessionExtra}`;
    const sessionKeyFinal = `vehicleSearchParams/${sessionKey}89Search`;
    const sessionValue = {
      qsMake: selectedMake,
      qsModel: selectedModel,
      qsVanType: selectedType,
      qsMinMonthly: selectedMin,
      qsMaxMonthly: selectedMax,
    };
    sessionStorage.setItem(sessionKeyFinal, JSON.stringify(sessionValue));
  }

  /**
   * Creates a URL based on the selections made
   * @method createSubmitURL
   * @returns {string} The resultant URL
   */
  function createSubmitURL() {
    let sessionBase = 'new-vans';
    const makeSelected = makeSelect.value !== '';
    const modelSelected = modelSelect.value !== '';
    const typeSelected = typeSelect.value !== '';
    const minSelected = minSelect.value !== '';
    const maxSelected = maxSelect.value !== '';
    const urlFriendlyMake = makeSelect.value.toLowerCase().split(' ').join('-');
    const urlFriendlyModel = modelSelect.value.toLowerCase().split(' ').join('-');
    const sessionExtra = `${makeSelect.value}${modelSelect.value}`;

    const urlFriendlyType = typeSelect.value
      .toLowerCase()
      .replace('van', '')
      .replace('dropsidetipper', 'dropside-tipper')
      .replace('pickup', 'pick-up');

    // -- Make only selected ----------------
    if (makeSelected && !modelSelected && !typeSelected && !minSelected && !maxSelected) {
      const sessionBase = `${urlFriendlyMake}-vans`;
      setSession(sessionBase);
      return `/${sessionBase}/`;
    }

    // -- Type only selected ----------------
    if (!makeSelected && !modelSelected && typeSelected && !minSelected && !maxSelected) {
      if (urlFriendlyType === 'conversions') {
        setSession('conversions');
        return '/conversions/';
      }
      if (urlFriendlyType === 'minibus') {
        setSession('minibus');
        return '/minibus/';
      }
      sessionBase = `${urlFriendlyType}-vans`;
      setSession(sessionBase);
      return `/${sessionBase}/`;
    }

    // -- Make & model selected -------------
    if (makeSelected && modelSelected && !typeSelected && !minSelected && !maxSelected) {
      sessionBase = `${urlFriendlyMake}-vans/${urlFriendlyMake}-${urlFriendlyModel}`;
      setSession(sessionBase, sessionExtra);
      return `/${sessionBase}/`;
    }

    // -- Default condition -----------------
    setSession(sessionBase);
    return '/new-vans/';
  }

  /**
   * Populates the budget display based on the selections
   * @method populateBudgetDisplay
   */
  function populateBudgetDisplay() {
    const budgetDisplay = `Budget: ${minSelect.options[minSelect.selectedIndex].text} → ${
      maxSelect.options[maxSelect.selectedIndex].text
    }`;
    budgetSelect.innerHTML = budgetDisplay;
  }

  /**
   * Resets the options in the selects specified in the passed array
   * @method resetOptions
   * @param {array} optionArray
   */
  function resetOptions(optionArray) {
    optionArray.forEach((option) => {
      option.options[0].selected = true;
    });
  }

  /**
   * Enables or disables the budget reset button based on the selections
   * @method enableBudgetReset
   */
  function enableBudgetReset() {
    resetBudget.disabled = ![minSelect, maxSelect].some((el) => el.value !== '');
  }

  /**
   * A handler to process clicks that aren't on selects or buttons
   * @method overallHandler
   * @param {object} e
   */
  function overallHandler(e) {
    const hideBudgetDropdown = !(
      e.target.classList.contains('js-budget-display') ||
      e.target.classList.contains('js-budget-dropdown')
    );
    if (hideBudgetDropdown) {
      budgetDropDown.classList.add('hide__default');
      budgetHolder.classList.remove('budget-holder--bordered');
    }
  }

  /**
   * A change handler to process changes to the makes select
   * @method makesChangeHandler
   */
  async function makesChangeHandler() {
    await loadingDisplay();
    resetOptions([minSelect, maxSelect, modelSelect, typeSelect]);
    data = await getData();
    if (makeSelect.value !== '') {
      buildSelect(getFacetData(data, 'model'), modelSelect);
      buildSelect(getFacetData(data, 'vanType'), typeSelect);
      buildBudgetSelects(createRange(data));
      populateCount(getCount(data), makeSelect.value);
      resetBudgetDisplay();
    } else {
      resetFilters();
    }
    enableMainReset();
  }

  /**
   * A change handler to process changes to the models select
   * @method modelChangeHandler
   */
  async function modelChangeHandler() {
    await loadingDisplay();
    resetOptions([minSelect, maxSelect, typeSelect]);
    data = await getData();
    buildSelect(getFacetData(data, 'vanType'), typeSelect);
    buildBudgetSelects(createRange(data));
    populateCount(getCount(data));
    resetBudgetDisplay();
  }

  /**
   * A change handler to process changes to the types select
   * @method typeChangeHandler
   */
  async function typeChangeHandler() {
    await loadingDisplay();
    resetOptions([minSelect, maxSelect]);
    data = await getData();
    buildBudgetSelects(createRange(data));
    resetBudgetDisplay();
    populateCount(getCount(data));
    enableMainReset();
  }

  /**
   * A change handler to process changes to the min or max selects
   * @method budgetChangeHandler
   */
  async function budgetChangeHandler() {
    await loadingDisplay();
    data = await getData();
    populateCount(getCount(data));
    processBudget('min');
    processBudget('max');
    populateBudgetDisplay();
    enableMainReset();
    enableBudgetReset();
  }

  /**
   * A change handler to process changes as a result of budget changes
   * @method budgetChangeHandler
   */
  function budgetDisplayHandler() {
    budgetDropDown.classList.toggle('hide__default');
    budgetHolder.classList.toggle('budget-holder--bordered');
  }

  /**
   * A change handler to process a click event on the reset budget button
   * @method resetBudgetHandler
   */
  async function resetBudgetHandler() {
    budgetHolder.classList.remove('budget-holder--bordered');
    await loadingDisplay();
    const selects = [minSelect, maxSelect];
    selects.forEach((selectElement) => {
      selectElement.disabled = false;
      selectElement.selectedIndex = 0;
    });
    processBudget('min');
    processBudget('max');
    data = await getData();
    populateCount(getCount(data));
    populateBudgetDisplay();
    enableMainReset();
    enableBudgetReset();
  }

  /**
   * A change handler to process a click on the budget done button
   * @method budgetDoneHandler
   */
  function budgetDoneHandler() {
    budgetDisplayHandler();
  }

  /**
   * A change handler to process a click on the search button
   * @method submitHandler
   */
  function submitHandler() {
    window.location.href = createSubmitURL();
  }

  /**
   * Creates all the event listeners for the app
   * @method createEventListeners
   */
  function createEventListeners() {
    overallContainer.addEventListener('click', overallHandler);
    makeSelect.addEventListener('change', makesChangeHandler);
    modelSelect.addEventListener('change', modelChangeHandler);
    typeSelect.addEventListener('change', typeChangeHandler);
    minSelect.addEventListener('change', () => {
      budgetChangeHandler('min');
    });
    maxSelect.addEventListener('change', () => {
      budgetChangeHandler('max');
    });
    resetFiltersButton.addEventListener('click', resetFilters);
    budgetSelect.addEventListener('click', budgetDisplayHandler);
    resetBudget.addEventListener('click', resetBudgetHandler);
    budgetDone.addEventListener('click', budgetDoneHandler);
    submitButton.addEventListener('click', submitHandler);
  }

  /**
   * @method init
   * Initialises the make-model-search
   */
  async function init() {
    data = await getData();
    resetFilters();
    createEventListeners();
  }
  init();
})(window, document);
